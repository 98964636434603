import { tns } from 'tiny-slider';

const matchSlider = document.querySelectorAll('.match-slider');
if (matchSlider.length) {
    matchSlider.forEach((slider) => {
        const startDesktop = slider.getAttribute('data-start-desktop');
        const startTablet = slider.getAttribute('data-start-tablet');
        const startMobile = slider.getAttribute('data-start-mobile');
        new tns({
            container: slider,
            loop: false,
            nav: false,
            controls: false,
            mouseDrag: true,
            items: 1,
            startIndex: startMobile ? startMobile : 0,
            responsive: {
                992: {
                    items: 2,
                    startIndex: startTablet ? startTablet : 0,
                },
                1436: {
                    items: 3,
                    startIndex: startDesktop ? startDesktop : 0,
                },
            },
        });
    });
}

const topstorySlider = document.querySelector('.topstory-slider');
if (topstorySlider) {
    const timeout = topstorySlider.dataset.timeout;
    new tns({
        container: topstorySlider,
        nav: true,
        controls: false,
        mouseDrag: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: timeout ? timeout : 5000,
        rewind: true,
        infinite: true,
    });
}

const partnersSlider = document.querySelectorAll('.partners-slider');
if (partnersSlider) {
    if (!window.matchMedia('(prefers-reduced-motion): reduce').matches) {
        window.onload = () => {
            initAnimation();
        };
    }

    function initAnimation() {
        partnersSlider.forEach((slider) => {
            const sliderInner = slider.querySelector('.partners-slider-inner');

            if (sliderInner.scrollWidth >= document.body.clientWidth) {
                slider.setAttribute('data-animated', true);
                const sliderContent = Array.from(sliderInner.children);
                sliderContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    duplicatedItem.setAttribute('aria-hidden', true);
                    sliderInner.appendChild(duplicatedItem);
                });
            }
        });
    }
}
